import { StabilityGeoJsonProperties } from '@/model/snowprofilerModels'
import { i18n } from '@/plugins/i18n'

const t = i18n.global.t
const LINE_BREAK_SEPERATOR = '<br />'
const COMMA_SEPERATOR = ', '
const SEMICOLON_SEPERATOR = '; '

export const getSnowprofileMouseOver = (
  snowprofileFeature: StabilityGeoJsonProperties
): string => {
  let htmlMouseOver = `${t('common.altitude')}: ${
    snowprofileFeature.elevation
  }${t('common.unit.meter')}, ${t('danger.label.aspect')}: ${
    snowprofileFeature.aspect ? snowprofileFeature.aspect : t('snow.label.flat')
  }`

  htmlMouseOver +=
    htmlMouseOver && htmlMouseOver.length > 0 ? LINE_BREAK_SEPERATOR : ''
  htmlMouseOver += getRutschblockMouseOver(snowprofileFeature)
  htmlMouseOver += getECTMouseOver(snowprofileFeature)

  return htmlMouseOver
}

const getRutschblockMouseOver = (
  snowprofileFeature: StabilityGeoJsonProperties
): string => {
  let rbHtmlText = ''

  snowprofileFeature.rutschblockTests.forEach((rbTest) => {
    rbHtmlText = addBreakWhenHaveTestText(rbHtmlText)

    if (rbTest.noFailure) {
      rbHtmlText += `RB-${i18n.global.t('snowprofile.noFracture')}`
    } else {
      rbTest.layers.forEach((rbLayer, layerIdx) => {
        const rbParts = []

        if (layerIdx > 0) {
          rbHtmlText = addSeparatorIfTestTextNotEmpty(
            rbHtmlText,
            SEMICOLON_SEPERATOR
          )
        }

        rbParts.push(rbLayer.score)
        if (rbLayer.releaseType) {
          rbParts.push(
            i18n.global.t(`snowprofile.RBStability.${rbLayer.releaseType}`)
          )
        }
        rbParts.push(
          getSlab(
            snowprofileFeature.snowHeightCalculated,
            rbLayer.referenceHeight
          )
        )

        rbHtmlText += getSeperatedString(rbParts, COMMA_SEPERATOR)
      })
    }
  })

  return addBreakWhenHaveTestText(rbHtmlText)
}

const getECTMouseOver = (
  snowprofileFeature: StabilityGeoJsonProperties
): string => {
  let ectHtmlText = ''

  snowprofileFeature.extendedColumnTests.forEach((ect) => {
    ectHtmlText = addBreakWhenHaveTestText(ectHtmlText)

    if (ect.noFailure) {
      ectHtmlText += `ECT-${i18n.global.t('snowprofile.noFracture')}`
    } else {
      ect.layers.forEach((ectLayer, layerIdx) => {
        const ectParts = []

        if (layerIdx > 0) {
          ectHtmlText = addSeparatorIfTestTextNotEmpty(
            ectHtmlText,
            SEMICOLON_SEPERATOR
          )
        }

        ectParts.push(ectLayer.ectText)
        ectParts.push(
          getSlab(
            snowprofileFeature.snowHeightCalculated,
            ectLayer.referenceHeight
          )
        )

        ectHtmlText += getSeperatedString(ectParts, COMMA_SEPERATOR)
      })
    }
  })
  return ectHtmlText
}

const getSlab = (
  snowHeightCalculated: number,
  referenceHeight: number
): string => {
  return `Slab=${snowHeightCalculated - referenceHeight}${t(
    'common.unit.centimeter'
  )}`
}

const addBreakWhenHaveTestText = (testText: string): string => {
  return addSeparatorIfTestTextNotEmpty(testText, LINE_BREAK_SEPERATOR)
}

const addSeparatorIfTestTextNotEmpty = (
  testText: string,
  separator: string
): string => {
  return testText.length > 0 ? `${testText}${separator}` : testText
}

const getSeperatedString = (parts: string[], seperator: string): string => {
  let result = ''

  parts.forEach((part) => {
    if (part && part.length > 0) {
      result += result && result.length > 0 ? `${seperator}${part}` : `${part}`
    }
  })

  return result
}
