<template>
  <b-card header-tag="header" footer-tag="footer">
    <template #header>
      <b-row>
        <b-col>
          <b>{{ $t(`ui.feedback.measurement.summer`) }} {{ code }}</b>
        </b-col>
        <b-col class="text-right" @click="datepicker = !datepicker">
          <span class="date">{{ formattedDate }}</span>
          &nbsp;&nbsp;
          <font-awesome-icon icon="fa-solid fa-calendar-alt" />
        </b-col>
      </b-row>

      <b-form class="dateForm" v-if="datepicker">
        <b-container>
          <b-row align-h="center">
            <calendar-component
              inline
              class="calendar-picker"
              v-model="dateString"
              name="datepicker"
              @update:model-value="setDate"
              :disabled-dates="disabledDates"
              :min="minDate"
              :max="maxDate"
              :show-icon="false"
            />
          </b-row>
        </b-container>
      </b-form>
    </template>

    <template #footer>
      <b-row>
        <b-col class="text-left">
          <b-button type="button" variant="warning" @click="routeBack()">{{
            $t('ui.cancel')
          }}</b-button>
        </b-col>
        <b-col class="text-right">
          <b-button
            @click="onSubmit"
            type="button"
            variant="success"
            :disabled="!valid"
            ><font-awesome-icon
              icon="fa-solid fa-paper-plane"
            ></font-awesome-icon>
            {{ $t('ui.send') }}</b-button
          >
        </b-col>
      </b-row>
    </template>

    <b-alert
      v-if="alert || hasActiveTimeserieError"
      show
      dismissible
      variant="warning"
    >
      {{ alert }}
      {{ hasActiveTimeserieError }}
    </b-alert>

    <b-form
      v-if="!alert && hasActiveTimeserieError == null"
      id="stdPlot.input.form"
    >
      <b-row>
        <b-col md="6">
          <b-form-group :label="$t('measurement.label.timestamp')">
            <b-form-input
              size="sm"
              type="time"
              v-model="form.time"
              :state="timeState"
              aria-describedby="timeFeedback"
            />
            <b-form-invalid-feedback id="timeFeedback">
              {{ $t('msg.selectValidTime') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Sommermeldung -->
      <b-row>
        <b-col md="6">
          <b-form-group cols="md-6">
            <template #label>
              <Label
                :text="$t('measurement.label.hn') + ' [cm]'"
                :info="$t('help.measurement.hn')"
              />
            </template>
            <b-form-input
              :disabled="!isBeobHNTimeserieIsActive"
              v-model="form.hn"
              type="text"
              :state="hnState"
              aria-describedby="hnFeedback"
            />
            <b-form-invalid-feedback id="hnFeedback">
              {{ $t('msg.selectValidValue') }} {{ $t('msg.onlyInteger') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group cols="md-6">
            <template #label>
              <Label
                :text="$t('measurement.label.hs') + ' [cm]'"
                :info="$t('help.measurement.hs')"
              />
            </template>
            <b-form-input
              :disabled="!isBeobHSTimeserieIsActive"
              v-model="form.hs"
              type="text"
              :state="hsState"
              aria-describedby="hsFeedback"
            />
            <b-form-invalid-feedback id="hsFeedback">
              {{ $t('msg.selectValidValue') }} {{ $t('msg.onlyInteger') }}
            </b-form-invalid-feedback>
            <b-form-text id="hsLiveHelp">
              {{
                type === 'morning'
                  ? $t('measurement.label.previous')
                  : $t('measurement.label.morning')
              }}
              {{ previous }}&nbsp;cm
            </b-form-text>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group cols="md-6">
            <template #label>
              <Label
                :text="$t('measurement.label.hsw') + ' [mm]'"
                :info="$t('help.measurement.hsw')"
              />
            </template>
            <b-form-input
              v-model="form.hsw"
              type="text"
              :state="hswState"
              aria-describedby="hswFeedback"
            />
            <b-form-invalid-feedback id="hswFeedback">
              {{ $t('measurement.error.waterEquivTooHigh') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-group :label="$t('measurement.label.comment')">
        <template #label>
          <Label
            :text="$t('measurement.label.comment')"
            :info="$t('help.measurement.comment')"
          />
        </template>
        <b-form-textarea
          id="comment"
          v-model="form.comment"
          :rows="3"
          :state="commentState"
          aria-describedby="inputCommentHelp"
          size="lg"
        />
        <b-form-invalid-feedback id="inputCommentHelp">
          {{ $tc('msg.maxCharacters', 200) }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-form>

    <b-modal
      id="modalMeasurementTable"
      :title="$t('measurement.label.measurements') + code"
    >
      <b-table cols="6" striped hover :items="history"></b-table>
    </b-modal>

    <!-- show messages -->
    <div>
      <b-alert
        v-if="statusObservation.message"
        show
        dismissible
        :variant="statusObservation.type"
      >
        {{ statusObservation.message }}
      </b-alert>
      <b-alert
        v-if="statusHSW.message"
        show
        dismissible
        :variant="statusHSW.type"
      >
        {{ statusHSW.message }}
      </b-alert>
      <b-alert v-if="!studyplotDetails" show dismissible variant="danger">
        {{ $t('snowprofileHSW.noStudyplotDetailsLoaded') }}
      </b-alert>
    </div>
  </b-card>
</template>

<script>
import moment from 'moment'
import CalendarComponent from '../../calendar/CalendarComponent'
import Label from '../../InfoLabel'
import { StudyplotService } from '@/services/StudyplotService'
import { i18nMixin } from '../../I18N'
import { inputAllowedChecker } from '@/components/studyplot/studyplotInput/InputAllowedChecker'
import {
  hasStationAnActiveBeobTimeserie,
  HN_MEASURAND_CODE,
  HS_MEASURAND_CODE,
} from '@/model/studyplotInput'
import { DateTime } from 'luxon'
import { deepClone } from '@/scripts/common'
import { inputSummer } from '@/components/studyplot/studyplotInput/inputSummer'

export default {
  setup() {
    const { createFullDensityProfile } = inputSummer()

    return { createFullDensityProfile }
  },

  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Label, CalendarComponent },
  mixins: [i18nMixin, inputAllowedChecker],
  props: ['code', 'type'],
  data() {
    return {
      routeOrigin: '/observation/list',
      date: new Date(),
      datepicker: false,
      disabledDates: [],
      minDate: moment().add(-30, 'd').toISOString(),
      maxDate: moment().toISOString(),
      selection: null,

      /** snowprofile form */
      form: {
        studyplot: this.code,
        type: this.type,
        time: '07:30',
        hsw: null,
        hs: null,
        hn: null,
        comment: null,
      },

      processing: null,
      measurements: [],
      alert: null,
      sendError: false,
      prepareError: false,
      new: true,

      profiles: [],
      statusObservation: { message: null, type: 'info' },
      statusHSW: { message: null, type: 'info' },
      studyplotDetails: null,
    }
  },

  methods: {
    // eslint-disable-next-line complexity
    onSubmit() {
      if (this.processing === true) {
        return
      }
      this.sendError = false
      this.statusObservation.message = null
      this.statusHSW.message = null
      this.processing = true
      const self = this

      if (this.existing) {
        this.form.oldDate = this.form.date
      }

      this.form.date = moment(this.date).format('YYYY-MM-DD')
      this.form.hn = this.form.hn ? this.form.hn.replace(',', '.') : null

      if (['.1', '.2', '.4', '0.1', '0.2', '0.4'].includes(this.form.hn)) {
        this.form.hn = '0.3'
      }

      /** Submit to studyplot
       * hs, hn, comment
       */
      const promiseL = []

      if (!this.prepareError) {
        promiseL.push(
          this.$store
            .dispatch(
              'studyplot/' +
                (this.existing ? 'updateMeasurement' : 'addMeasurement'),
              {
                measurementForm: this.form,
                isManagmentMode: this.isManagmentMode,
              }
            )
            .then(
              () => {
                const msg = this.$i18n.t('snowprofileHSW.sendToObservationOk')
                console.debug(msg)
                this.statusObservation.message = msg
                this.statusObservation.type = 'success'
              },
              (reason) => {
                this.sendError = true
                let message = reason.message
                if (
                  reason &&
                  reason.response &&
                  reason.response.data &&
                  reason.response.data.message
                ) {
                  message = reason.response.data.message
                }

                const msg = this.$i18n.t(
                  'snowprofileHSW.sendToObservationError'
                )
                const msgMax = this.$i18n.t(
                  'snowprofileHSW.sendToObservationErrorMaxPerDay'
                )
                console.error(msg + ' ' + msgMax + ' ' + message)
                this.statusObservation.message = msg + ' ' + msgMax
                this.statusObservation.type = 'danger'
              }
            )
        )
      }

      /** submit also to snowprofiler
       * hs and hsw
       */
      if (this.form.hsw !== null) {
        const hsw = this.prepareHSW(this.form)

        if (!hsw) {
          const msg = this.$i18n.t(
            'snowprofileHSW.sendToSnowprofileErrorNoObsGroup'
          )
          console.error(msg)
          this.statusHSW.message = msg
          this.statusHSW.type = 'danger'
          return
        }

        promiseL.push(
          this.$store.dispatch('snowprofile/addDensityProfile', hsw).then(
            () => {
              const msg = this.$i18n.t('snowprofileHSW.sendToSnowprofileOk')
              console.debug(msg)
              this.statusHSW.message = msg
              this.statusHSW.type = 'success'
            },
            (reason) => {
              this.sendError = true
              let message = reason.message
              if (
                reason &&
                reason.response &&
                reason.response.data &&
                reason.response.data.message
              ) {
                message = reason.response.data.message
              }
              const msg = this.$i18n.t('snowprofileHSW.sendToSnowprofileError')
              console.error(msg + ' ' + message)
              this.statusHSW.message = msg + ' ' + message
              this.statusHSW.type = 'danger'
            }
          )
        )
      }

      Promise.all(promiseL).then(() => {
        self.processing = false
        if (!this.sendError) {
          self.routeBack()
        }
      })
    },

    /** Prepare the hsw payload
     * @returns hsw
     */
    // eslint-disable-next-line complexity
    prepareHSW() {
      let hsw = null
      let observerGroup = null

      if (this.$store?.state?.profile?.observerGroup) {
        observerGroup = this.$store.state.profile.observerGroup
      }

      const obs = {
        userId: this.$store.state.user.user.id,
        firstName: null,
        lastName: null,
      }

      this.$store.state.user.users.some((user) => {
        if (user.id === obs.userId) {
          obs.firstName = user.firstName
          obs.lastName = user.lastName
          return true
        }
      })

      if (
        observerGroup &&
        this.studyplotDetails?.location?.coordinates?.length === 2 &&
        this.studyplotDetails.location.type === 'Point'
      ) {
        hsw = this.createFullDensityProfile(
          this.studyplotDetails,
          DateTime.fromFormat(
            `${this.form.date} ${this.form.time}`,
            'yyyy-MM-dd HH:mm'
          ),
          observerGroup,
          obs.firstName + ' ' + obs.lastName,
          Number(this.form.hs),
          Number(this.form.hsw)
        )
      }
      return hsw
    },

    /** history back */
    routeBack() {
      if (this.routeOrigin.includes('/observation/list')) {
        if (this.new) {
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetStudyplotId:
                this.$store.state.profile.studyplotIdToObserverGroup,
            },
          })
          this.$root.$emit('bv::show::modal', 'obsModal')
        } else {
          const measurement = this.$store.state.observation.selected.measurement
          this.$router.push({
            path: '/observation/list',
            query: {
              pathSetStudyplotId:
                this.$store.state.profile.studyplotIdToObserverGroup,
              openObs:
                'studyplot.' +
                measurement.studyplot +
                '.' +
                measurement.type +
                '.' +
                measurement.date,
            },
          })
        }
      } else {
        this.$router.push({
          path: this.routeOrigin,
          query: {
            openObs: this.$store.state.observation.mapselectedId,
          },
        })
      }
    },

    reset() {
      this.form = {
        studyplot: this.code,
        hs: null,
        hn: null,
        hsw: null,
        comment: null,
      }

      this.profiles = null
    },

    setDate(date) {
      this.dateString = date
      this.checkDate()
      this.datepicker = false
      this.$store.commit('observation/SET_DATE', this.date)

      this.resetSendError()
    },

    resetSendError() {
      this.sendError = false
      this.statusObservation.message = null
      this.statusHSW.message = null
    },

    checkDate() {
      const ts = moment(this.date).startOf('day').unix() * 1000
      if (
        this.disabledDates.find((d) => {
          return moment(d).valueOf() === ts
        })
      ) {
        this.alert = this.$t('measurement.label.duplicate', {
          date: moment(this.date).format('DD.MM.YYYY'),
        })
      } else {
        this.alert = null
      }
    },

    nvl(value) {
      return value === null ? true : value
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeOrigin = from.fullPath
    })
  },

  mounted() {
    if (this.existing) {
      this.new = false
      this.form = deepClone(this.$store.state.observation.selected.measurement)
      this.form.hn = this.form.hn.toString()
      this.date = new Date(this.form.date)
    } else {
      this.setDate(moment().format('DD.MM.YYYY'))
    }

    // load all measurement dates
    StudyplotService.getMeasurements(this.code)
      .then((response) => {
        this.measurements = response
        response.forEach((o) => {
          if (o.type === this.type) {
            this.disabledDates.push(moment(o.date).format('YYYY-MM-DD'))
          }
        })
        if (!this.existing) {
          this.checkDate()
        }
      })
      .catch((e) => {
        console.error('Could not read measurements:' + e)
      })

    // load summer station details
    StudyplotService.getStudyplotByCode(this.code)
      .then((response) => {
        this.studyplotDetails = response
      })
      .catch((e) => {
        console.error(
          'Could not read details for studyplot with id ' +
            this.code +
            '. Error: ' +
            e
        )
        this.studyplotDetails = undefined
        this.prepareError = true
      })
  },

  unmounted() {
    this.$store.commit('observation/SET_SELECTED', null)

    this.$store.commit('snowprofile/SET_DETAIL_SNOWPROFILES', [])

    this.reset()
  },

  computed: {
    isBeobHNTimeserieIsActive() {
      return hasStationAnActiveBeobTimeserie(
        DateTime.fromJSDate(this.date),
        this.$store.getters['studyplot/getStudyplot'](this.code),
        [HN_MEASURAND_CODE]
      )
    },
    isBeobHSTimeserieIsActive() {
      return hasStationAnActiveBeobTimeserie(
        DateTime.fromJSDate(this.date),
        this.$store.getters['studyplot/getStudyplot'](this.code),
        [HS_MEASURAND_CODE]
      )
    },
    hasActiveTimeserieError() {
      return this.checkItHasActiveTimeserieError(
        this.date,
        this.$store.getters['studyplot/getStudyplot'](this.code)
      )
    },
    existing() {
      return this.$store.state.observation.selected
    },

    formattedDate() {
      return moment(this.date).format('DD.MM.YY')
    },

    history() {
      const history = []
      for (let i = 8; i >= 0; i--) {
        const date = moment().add(-i, 'days')
        const m = this.measurements.find(
          (el) => el.date === date.format('YYYY-MM-DD')
        )
        history.push({
          Datum: date.format('DD.MM.YYYY'),
          hs: m ? m.hs : null,
        })
      }
      return history
    },

    previous() {
      const date = moment(this.date).add(
        this.type === 'morning' ? -1 : 0,
        'days'
      )
      const m = this.measurements.find(
        (el) => el.date === date.format('YYYY-MM-DD') && el.type === 'morning'
      )
      return m ? m.hs : '-'
    },

    // Zeit darf nicht in der Zukunft liegen
    timeState() {
      if (
        !/^([0-1]{1}[0-9]{1}|20|21|22|23):[0-5]{1}[0-9]{1}$/.test(
          this.form.time
        )
      )
        return false
      const [hour, minute] = this.form.time.split(':')
      const time = moment(this.date).set({ hour, minute, second: 0 })
      return moment().isAfter(time)
    },

    hswState() {
      if ((this.form.hsw !== 0 && this.hswValidation1) || !this.form.hsw)
        return true
      if (!/^[\d]+$/.test(this.form.hsw)) {
        return false
      }
      return this.hswValidation1
    },

    // The given density of snow (waterequiv/snow depth) cannot also be higher than the density of ice.
    hswValidation1() {
      const DENSITY_OF_ICE = 917
      return (
        this.form.hsw > 0 &&
        this.form.hsw % 1 === 0 &&
        (this.form.hsw * 100) / this.form.hs <= DENSITY_OF_ICE
      )
    },

    // Im Feld Schneehöhe dürfen nur ganze Zahlen eingegeben werden.
    hsState() {
      if (!this.form.hs) return null
      if (!/^[\d]+$/.test(this.form.hs)) return false
      return this.form.hs >= 0 && this.form.hs % 1 === 0
    },

    // Im Feld Neuschnee dürfen nur die Werte 0/0,1/0,2/0,3/0,4/1/2/3/……… bis 200 eingegeben werden.
    // eslint-disable-next-line complexity
    hnState() {
      if (!this.form.hn) return null
      if (!/^[\d,.]+$/.test(this.form.hn)) return false
      const hn = parseFloat(this.form.hn.replace(',', '.'))
      return (
        hn >= 0 &&
        hn <= 200 &&
        (hn === 0.1 || hn === 0.2 || hn === 0.3 || hn === 0.4 || hn % 1 === 0)
      )
    },

    commentState() {
      if (!this.form.comment) return null
      return this.form.comment.length <= 200
    },

    // eslint-disable-next-line complexity
    valid() {
      return (
        this.timeState &&
        this.nvl(this.hnState) &&
        this.nvl(this.hswState) &&
        this.nvl(this.hsState) &&
        this.nvl(this.commentState) &&
        this.hnState &&
        this.hswState &&
        this.hsState &&
        this.studyplotDetails &&
        !this.prepareError
      )
    },
    dateString: {
      get() {
        return moment(this.date).format('DD.MM.YYYY')
      },
      set(val) {
        this.date = moment(val, 'DD.MM.YYYY').toDate()
      },
    },
  },
}
</script>

<style scoped>
.date {
  font: bold 12px Helvetica;
  margin-top: 5px;
}
.disabled {
  color: #acacac;
}
.line {
  font-size: 1em;
}
</style>
