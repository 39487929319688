import BaseService from '@/services/BaseService'
import { AxiosInstance } from 'axios'
import {
  FullProfileCreateInput,
  ProfileSearchResult,
  StabilityGeoJson,
} from '@/model/snowprofilerModels'
import { DateTime } from 'luxon'
import { getCancelToken, WrappedAxiosCanceler } from '@/services/axiosUtils'

export class SnowprofilerService {
  private static _baseService?: BaseService

  private static get instance(): AxiosInstance {
    const baseService =
      this._baseService || new BaseService('SNOWPROFILER_SERVICE_URL', 5000)
    this._baseService = baseService
    return baseService.instance
  }

  public static async getSnowProfilesByUserCreateDateTimeRange(
    userId: string,
    startObsDate: DateTime,
    endObsDate: DateTime,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<ProfileSearchResult[]> {
    if (!userId || !startObsDate || !endObsDate || startObsDate > endObsDate) {
      throw new TypeError(
        '"getSnowProfilesByUserCreateDateTimeRange" snowprofile: paramters not valid !'
      )
    }

    const path = '/secure/profiles/search/byCreator'
    const searchParams = {
      startCreatedAt: startObsDate.toUTC().toISO(),
      endCreatedAt: endObsDate.toUTC().toISO(),
      // Format='305D9C0FE8D7444BDC3B4E6C307B67EE'
      createdBy: userId.replace(/-/g, '').toUpperCase(),
    }

    const result = await this.instance.get<ProfileSearchResult[]>(path, {
      params: searchParams,
      cancelToken: getCancelToken(wrappedAxiosCanceler),
    })
    return result.data
  }

  public static async getSnowProfilesSDSByObservationDateTimeRange(
    observationDateTimeRange: string,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<StabilityGeoJson> {
    const [dateTimePart, rangePart] = observationDateTimeRange.split('P')

    if (rangePart !== '1D') {
      throw new Error(`Unsupported date range '${rangePart}'`)
    }

    const parsedDateTime = DateTime.fromISO(dateTimePart, { zone: 'utc' })
    const startObsDate = parsedDateTime.startOf('day')
    const endObsDate = parsedDateTime.endOf('day')

    return SnowprofilerService.getSnowProfilesSDS(
      startObsDate,
      endObsDate,
      wrappedAxiosCanceler
    )
  }

  private static async getSnowProfilesSDS(
    startObsDate: DateTime,
    endObsDate: DateTime,
    wrappedAxiosCanceler?: WrappedAxiosCanceler
  ): Promise<StabilityGeoJson> {
    const path = '/secure/profiles/maps/stability/'
    const searchParams = {
      startDate: startObsDate.toISO(),
      endDate: endObsDate.toISO(),
    }

    const result = await this.instance.get<StabilityGeoJson>(path, {
      params: searchParams,
      cancelToken: getCancelToken(wrappedAxiosCanceler),
    })

    return result.data
  }

  public static async createNewDensityProfile(
    densityProfile: FullProfileCreateInput
  ): Promise<FullProfileCreateInput> {
    return this.instance.post('secure/profiles/import/', densityProfile, {
      params: { publish: true },
    })
  }
}
