import { ASPECTS } from '@/model/commonModels'
import {
  DANGER_LEVEL,
  DANGER_PROBLEM,
  DANGER_SUBDIVISION,
} from '@/model/dangerModels'

export const i18nMixin = {
  methods: {
    i18n(e, v) {
      return v ? this.$i18n.t(e + '.' + v) : ''
    },
    enums(e) {
      return enums[e]
    },
    options(g, e, notnull) {
      const options = enums[g][e].map((x) => {
        return {
          value: x,
          text: this.$i18n.t([g, e, x].join('.')),
        }
      })
      if (!notnull) {
        options.unshift({ value: null, text: this.$i18n.t('ui.select') })
      }
      return options
    },
  },
}

export const enums = {
  common: {
    aspect: ASPECTS,
    flag: [true, false],
  },
  // --------------------------------------------------------------------
  avalanche: {
    avalancheSize: ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'UNKNOWN'],
    avalancheType: ['SLAB', 'LOOSE_SNOW', 'FULL_DEPTH', 'UNKNOWN'],
    avalancheMoisture: ['DRY', 'WET', 'UNKNOWN'],
    triggerType: [
      'NATURAL',
      'PERSON',
      'EXPLOSIVE',
      'SNOW_GROOMER',
      'OTHER',
      'UNKNOWN',
    ],
    dateAccuracy: [
      'ACCURATE',
      'PM_1_DAY',
      'PM_2_DAY',
      'PM_4_DAY',
      'PM_8_DAY',
      'PM_16_DAY',
      'PM_32_DAY',
      'PM_64_DAY',
      'PM_128_DAY',
      'PM_6_MONTH',
      'INACCURATE',
    ],
    timeAccuracy: [
      'ACCURATE',
      'PM_15_MIN',
      'PM_30_MIN',
      'PM_1_H',
      'PM_2_H',
      'PM_4_H',
      'PM_6_H',
      'PM_12_H',
    ],
    startZoneSlopeAngle: ['0_30', '30_35', '35_40', '40_45', '45_90'],
    weakLayer: [
      'WITHIN_NEW_AND_DRIFTING_SNOW',
      'AT_THE_TRANSITION_TO_OLD_SNOW',
      'IN_OLD_SNOW',
      'IN_OLD_SNOW_CLOSE_TO_THE_GROUND',
    ],
    triggerPersonTypes: ['SKI', 'SNOWBOARD', 'SNOWSHOE', 'MOUNTAINEER'],
    triggerAscentDescent: ['ASCENT', 'DESCENT'],
    burialType: ['NOT', 'PARTIAL', 'COMPLETE'],
    personDamageType: ['UNINJURED', 'INJURED', 'DEAD'],
    recoveryType: ['SELF', 'COMPANION', 'RESCUE_TEAM'],
    searchDevice: [
      'VISIBLE',
      'SHOUT',
      'NOTICE',
      'LVS',
      'SEARCH_PROBE',
      'DOG',
      'TRANSPONDER',
      'DIG',
      'ABS',
      'THAW',
    ],
    caughtLocation: ['FREE_TERRAIN', 'ROUTE', 'BUILDING'],
    caughtFreeTerrainSport: [
      'SKI',
      'SNOWBOARD',
      'SNOWSHOE',
      'MOUNTAINEER',
      'OTHER',
    ],
    caughtFreeTerrainActivity: ['BACK_COUNTRY', 'FREERIDE'],
    caughtTransportRouteFunction: ['MAINTENANCE', 'USER'],
    caughtTransportRoute: [
      'STREET',
      'RAILWAY',
      'ROPEWAY',
      'PISTE',
      'CROSS_COUNTRY',
      'FOOTPATH',
      'BUILDING_SITE',
      'OTHER',
    ],
    caughtTransportRouteState: ['OPEN', 'CLOSED'],
    severalTimeAll: 'FULL_TIME',
    severalTime: ['FORENOON', 'AFTERNOON', 'NIGHT'],
  },
  // --------------------------------------------------------------------
  snow: {
    snowSurfaceCondition: [
      'SURFACE_HOAR_LARGE',
      'SURFACE_HOAR_SMALL',
      'LOOSE_FACETS',
      'LOOSE_NEW_SNOW',
      'MOIST',
      'HARD',
      'BREAKABLE',
      'UNEVEN',
      'OTHER',
    ],
  },
  // --------------------------------------------------------------------
  dangersign: {
    frequency: ['NONE', 'RARELY', 'OFTEN'],
    extension: ['LOCALIZED', 'EXTENSIVE'],
    flat: ['true'],
    snowdriftsize: ['NONE', 'SMALL', 'MEDIUM', 'LARGE'],
    distribution: ['ISOLATED', 'LOCALIZED', 'EXTENSIVE'],
    // avalancheObservation: [ 'true', 'false', 'NOT_POSSIBLE' ]
    avalancheObservation: ['NOT_POSSIBLE'],
  },
  // --------------------------------------------------------------------
  danger: {
    level: DANGER_LEVEL,
    subdivision: DANGER_SUBDIVISION,
    problem: DANGER_PROBLEM,
    pattern: ['NEW_SNOW', 'SNOWDRIFT', 'OLD_SNOW', 'UNDEFINED'],
    allDay: ['ALL_DAY', 'CHANGE_DURING_DAY'],
    altitudeAbove: [false, true],
  },
  // --------------------------------------------------------------------
  observation: {
    origin: ['OWN_TRACK', 'TRACKS', 'DISTANCE', 'THIRD_PARTY'],
  },
  // --------------------------------------------------------------------
  snowprofile: {
    RBStability: ['WB', 'MB', 'EB'],
  },
  // --------------------------------------------------------------------
  bulletin: {
    dangerMap: ['FIRST_DANGERMAP', 'SECOND_DANGERMAP'],
  },
  // --------------------------------------------------------------------
  user: {
    education: [
      'TOURING_SKIER',
      'TOURING_GUIDE',
      'SKIING_INSTRUCTOR',
      'MOUNTAIN_GUIDE',
      'RESCUE_SERVICE',
      'AVALANCHE_SERVICE',
      'OTHER',
    ],
  },
  // --------------------------------------------------------------------
  assessment: {
    snowpackStructure: [
      'FAVORABLE',
      'RATHER_FAVORABLE',
      'RATHER_UNFAVORABLE',
      'UNFAVORABLE',
    ],
    avalRelease: ['NONE', 'FEW', 'MANY', 'NOT_POSSIBLE'],
    roughness: ['ROUGH', 'MEDIUM', 'SMOOTH'],
    accumulation: ['LITTLE', 'MEDIUM', 'MUCH'],
    effectiveness: ['INEFFECTIVE', 'MEDIUM', 'EFFECTIVE'],
    hazard: ['IMPROBABLE', 'PROBABLE'],
    dangerTrend: [
      'STRONGLY_INCREASING',
      'INCREASING',
      'CONSTANT',
      'DECREASING',
      'STRONGLY_DECREASING',
    ],
    areaSectors: ['AREA', 'SECTORS'],
    breakingProbability: ['UNLIKELY', 'POSSIBLY_TO_LIKELY'],
    criticalAvalancheSize: ['UNLIKELY', 'POSSIBLY_TO_LIKELY'],
  },
  measure: {
    path: ['NO_MEASURE', 'CLOSURE', 'CLOSURE_WINTER'],
    building: ['NO_MEASURE', 'LOCKDOWN', 'EVACUATION'],
    warningLight: ['WARNING_LIGHT_ON', 'WARNING_LIGHT_OFF'],
    objectType: [
      'snowsport-infrastructure-mountain',
      'snowsport-infrastructure-valley',
      'municipalroad',
      'cantonalroad',
      'nationalroad',
      'railway',
      'building',
      'other',
    ],
  },
  legend: {
    legendLineType: ['NORMAL', 'THIN'],
  },
  triggering: {
    blastingMethods: [
      'HAND_CHARGE_BLASTING',
      'HELI_BLASTING',
      'PERMANENT_BLASTING_SYSTEM',
      'OTHER_BLASTING_METHOD',
    ],
  },
  creatorSystem: ['slfpro-workbench', 'slfpro-reply-public'],
}
