import { FullProfileCreateInput } from '@/model/snowprofilerModels'
import { DateTime } from 'luxon'
import store from '@/store'
import { Studyplot } from '@/services/StudyplotService'
import { round } from '@/scripts/numberUtils'

interface InputSummer {
  createFullDensityProfile: (
    studyplot: Studyplot,
    measureDate: DateTime,
    observerGroupId: string,
    observerName: string,
    snowHeight: number,
    waterequivalent: number
  ) => FullProfileCreateInput
}

export function inputSummer(): InputSummer {
  const createFullDensityProfile = (
    studyplot: Studyplot,
    measureDate: DateTime,
    observerGroupId: string,
    observerName: string,
    snowHeight: number,
    waterequivalent: number
  ) => {
    return {
      date: measureDate.toISO(),
      slopeAngle: 0,
      aspect: null,
      locationName: `${studyplot.label} ${cutEndingZero(
        studyplot.stationCode
      )}`,
      elevation: studyplot.altitude,
      coordinates: [
        studyplot.location.coordinates[0],
        studyplot.location.coordinates[1],
      ],
      observers: [{ name: observerName }],
      tags: [
        { key: 'observerGroupId', value: observerGroupId },
        {
          key: 'observerGroupName',
          value: store.getters['profile/getObserverGroupName'](observerGroupId),
        },
        { key: 'stationId', value: String(studyplot.stationId) },
        { key: 'stationCode', value: studyplot.stationCode },
        { key: 'stationLabel', value: studyplot.label },
      ],
      densityProfiles: [
        {
          subProfileId: 0,
          layers: [
            {
              referenceHeightTop: snowHeight,
              thickness: snowHeight,
              density: round((waterequivalent / snowHeight) * 100, 2),
            },
          ],
        },
      ],
      comment: null,
      commentIsConfidential: false,
      snowHeightMeasured: null,
      avalancheReleaseProfiles: [],
      compressionTests: [],
      extendedColumnTests: [],
      ramResistanceProfiles: [],
      stratigraphyProfiles: [],
      temperatureProfiles: [],
      rutschblockTests: [],
    } as FullProfileCreateInput
  }

  const cutEndingZero = (value: string) => {
    if (value.endsWith('0')) {
      return value.slice(0, -1)
    }
    return value
  }

  return { createFullDensityProfile }
}
