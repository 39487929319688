import {
  ASSESSMENT_DECISION,
  ASSESSMENT_SECTOR,
  ASSESSMENT_SITUATION,
  ATTACHMENT,
  AVALANCHE,
  DANGER,
  DANGERSIGN,
  SNOW,
  SNOWPROFILE,
  STUDYPLOTOBSERVATION,
  STUDYPLOTOBSERVATION_DANGER,
  STUDYPLOTOBSERVATION_MANAGEMENT,
  STUDYPLOTOBSERVATION_MORNING,
  STUDYPLOTOBSERVATION_SUMMER,
} from '@/scripts/const'
import { i18n } from '@/plugins/i18n'
import { EnvironmentService } from '@/services/EnvironmentService'
import {
  FeedbackMenu,
  StudyplotObservationMenu,
} from '@/components/observation/feedback/models'

const getSnowprofilerUrl = () => {
  return EnvironmentService.getOptionalVariable('SNOWPROFILER_UI_URL')
}
const getSnowprofilerMenuEntry = (labelCode: string) => {
  return {
    name: i18n.global.t(labelCode),
    shortName: i18n.global.t('ui.snowprofileShort'),
    type: SNOWPROFILE,
    path: '/{lang}/add-profile',
    externalUrl: getSnowprofilerUrl(),
    class: 'fa-regular fa-shovel',
    program: '',
    show: true,
    disabled: false,
  }
}

export default class FeedbackTabsMenus {
  public static getObservationCommonMenu(): Array<FeedbackMenu> {
    const observationMenu: FeedbackMenu[] = [
      {
        name: i18n.global.t('ui.feedback.snow'),
        shortName: i18n.global.t('ui.snow'),
        type: SNOW,
        path: '/observation/snow',
        class: 'fa-solid fa-snowflake',
        disabled: false,
      },
      {
        name: i18n.global.t('ui.feedback.dangersign'),
        shortName: i18n.global.t('ui.dangersign'),
        type: DANGERSIGN,
        path: '/observation/dangersign',
        class: 'fa-solid fa-exclamation-triangle',
        disabled: false,
      },
      {
        name: i18n.global.t('ui.feedback.danger'),
        shortName: i18n.global.t('ui.danger'),
        type: DANGER,
        path: '/observation/danger',
        class: 'fa-solid fa-flag-checkered',
        disabled: false,
      },
      {
        name: i18n.global.t('ui.feedback.avalanche'),
        shortName: i18n.global.t('ui.avalanche'),
        type: AVALANCHE,
        path: '/observation/avalanche',
        class: 'fa-solid fa-mountain',
        disabled: false,
      },
      {
        name: i18n.global.t('ui.attachment'),
        shortName: i18n.global.t('ui.attachmentShort'),
        type: ATTACHMENT,
        path: '/observation/attachment',
        class: 'fa-solid fa-images',
        disabled: false,
      },
    ]
    if (getSnowprofilerUrl()) {
      observationMenu.push(getSnowprofilerMenuEntry('ui.snowprofile'))
    }
    return observationMenu
  }

  public static getStudyplotCommonMenu(): Array<StudyplotObservationMenu> {
    const studyplotCommon: StudyplotObservationMenu[] = [
      {
        name: i18n.global.t('ui.feedback.measurement.morning'),
        shortName: i18n.global.t('ui.snow'),
        type: STUDYPLOTOBSERVATION_MORNING,
        path: '/observation/studyplot/:id/morning',
        class: 'fa-solid fa-ruler-vertical',
        program: '',
        show: true,
        disabled: false,
      },
      {
        name: i18n.global.t('ui.studyplotobservation'),
        shortName: i18n.global.t('ui.studyplotobservation'),
        type: STUDYPLOTOBSERVATION,
        path: '/observation/studyplotobservation/:id',
        class: 'fa-solid fa-binoculars',
        program: 'STUDYPLOTOBSERVATION',
        show: false,
        disabled: false,
      },
      {
        name: i18n.global.t('ui.feedback.danger'),
        shortName: i18n.global.t('ui.danger'),
        type: STUDYPLOTOBSERVATION_DANGER,
        path: '/observation/danger/:id',
        class: 'fa-solid fa-flag-checkered',
        program: 'EVALUATION',
        show: false,
        disabled: false,
      },
      /* Eigentlich ist die Sommer Messung eine Sommer-Morgen Messung
         Inhaltlich unterscheiden sich die Morgen- und Sommer- Messungen
         nur durch die Felder, bezüglich Ablage in slfpro.
         Winter-Morgen Messung: hs, hn, hnw
         Sommer-Morgen Messung: hs, hn, hsw, (hsw wird nur in den Schneeprofildienst geschrieben)
      */
      {
        name: i18n.global.t('ui.feedback.measurement.summer'),
        shortName: i18n.global.t('snowprofileHSW.snowSummer'),
        type: STUDYPLOTOBSERVATION_SUMMER,
        path: '/observation/studyplotSummer/:id/morning',
        class: 'fa-solid fa-ruler-vertical',
        program: '',
        show: true,
        disabled: false,
      },
    ]
    if (getSnowprofilerUrl()) {
      studyplotCommon.push(getSnowprofilerMenuEntry('ui.profileMeasuringSite'))
    }
    return studyplotCommon
  }

  public static getAssessmentMenu(): Array<FeedbackMenu> {
    return [
      {
        name: i18n.global.t('assessment.menu.createAssessment'),
        shortName: i18n.global.t('assessment.shortName.assessment'),
        type: ASSESSMENT_SITUATION,
        path: '/assessment/areaAssessment',
        class: 'fa-solid fa-eye',
        disabled: false,
      },
      {
        name: i18n.global.t('ui.feedback.danger'),
        shortName: i18n.global.t('ui.danger'),
        type: STUDYPLOTOBSERVATION_DANGER,
        path: '/assessment/danger',
        class: 'fa-solid fa-flag-checkered',
        disabled: false,
      },
      {
        name: i18n.global.t('assessment.menu.section'),
        shortName: i18n.global.t('assessment.shortName.section'),
        type: ASSESSMENT_SECTOR,
        path: '/assessment/areaAssessment/section',
        class: 'fa-solid fa-eye',
        disabled: false,
      },
      {
        name: i18n.global.t('assessment.menu.measureAssessment'),
        shortName: i18n.global.t('assessment.shortName.measureAssessment'),
        type: ASSESSMENT_DECISION,
        path: '/assessment/measureAssessment',
        class: 'fa-solid fa-eye',
        disabled: false,
      },
      {
        name: i18n.global.t('ui.attachment'),
        shortName: i18n.global.t('ui.attachmentShort'),
        type: ATTACHMENT,
        path: '/assessment/attachment',
        class: 'fa-solid fa-images',
        disabled: false,
      },
    ]
  }

  public static getStudyplotManagementMenu(): Array<StudyplotObservationMenu> {
    return [
      {
        name: i18n.global.t('ui.feedback.studyplotManagement.manage'),
        shortName: i18n.global.t('ui.feedback.studyplotManagement.manage'),
        type: STUDYPLOTOBSERVATION_MANAGEMENT,
        path: '/studyplotManagement',
        class: 'fa-solid fa-edit',
        program: '',
        show: true,
        disabled: false,
      },
    ]
  }
}
