<template>
  <b-modal
    id="obsModal"
    ref="obsModal"
    title="Beobachtung"
    centered
    no-fade
    hide-header
    hide-footer
    :static="staticTest"
    lazy
    class="fixed-bottom"
    :visible="$route.query.o === '1'"
  >
    <FeedbackTabs :hasMissAval="hasMissingAvalanches()" :setTabId="tabId">
      <template v-slot:tabContent="slotProps">
        <div class="tab-wrapper">
          <b-link
            v-for="entry in slotProps.tabElement.menu"
            v-bind:key="entry.id"
            :href="
              entry.externalUrl
                ? getExternalFeedbackUrl(
                    entry.externalUrl,
                    entry.path,
                    slotProps.tabElement
                  )
                : undefined
            "
            :disabled="entry.disabled"
            @click="
              setObserverGroupOrStudyplotOrAvalService(
                entry.path,
                slotProps.tabElement,
                entry.externalUrl
              )
            "
          >
            <b-row align-v="center">
              <b-col cols="2">
                <b-button
                  variant="outline"
                  class="obs-button"
                  :disabled="entry.disabled"
                >
                  <font-awesome-icon :icon="entry.class" />
                </b-button>
              </b-col>
              <b-col cols="8">
                {{
                  entry.name.replace(/:id/, slotProps.tabElement.studyplotId)
                }}
                <font-awesome-icon
                  v-if="entry.externalUrl != null"
                  :icon="['fas', 'up-right-from-square']"
                />
              </b-col>
              <b-col
                cols="2"
                class="d-flex justify-content-between align-items-center"
              >
                <b-badge
                  v-if="messageCountForTypeVisible(entry.type)"
                  pill
                  :class="getCountPillClasses(entry.type)"
                >
                  {{
                    getMessageCountToMenuItem(slotProps.tabElement, entry.type)
                  }}
                </b-badge>
              </b-col>
            </b-row>
          </b-link>
        </div>
      </template>
    </FeedbackTabs>
  </b-modal>
</template>

<script>
import FeedbackTabs from './FeedbackTabs.vue'
import { listMixin } from '../list/ListMixin.js'
import moment from 'moment'
import {
  AVALANCHE,
  DANGERSIGN,
  STUDYPLOTOBSERVATION,
  LDREGION,
  ASSESSMENT_SITUATION,
  ASSESSMENT_SECTOR,
  ASSESSMENT_DECISION,
  STUDYPLOTOBSERVATION_SUMMER,
  STUDYPLOTOBSERVATION_MANAGEMENT,
  SNOWPROFILE_FLAT,
  SNOWPROFILE_SLOPE,
  SNOW_PROFILE,
} from '@/scripts/const'
import { mapState } from 'vuex'
import { feedback } from '@/components/observation/feedback/feedback.ts'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Feedback',
  mixins: [listMixin],
  components: {
    FeedbackTabs,
  },
  props: ['static'],
  data() {
    return {
      tabId: undefined,
    }
  },
  setup() {
    return feedback()
  },
  watch: {
    isFeedbackLoaded: function (isLoaded) {
      if (isLoaded) {
        this.tabId = undefined
        if (this.$route.query.pathSetObsGroupId) {
          this.tabId = this.$route.query.pathSetObsGroupId
        }
        if (this.$route.query.pathSetStudyplotId) {
          this.tabId = this.$route.query.pathSetStudyplotId
        }
        this.createObservationsAndAvalanchesAndAssessments()
      }
    },
  },
  computed: {
    ...mapState('observation', ['isFeedbackLoaded']),
    staticTest() {
      // NOTE: property static set to true only for test purposes to have modal within component
      return this.static && this.static === true ? true : false
    },
  },
  methods: {
    setObserverGroupOrStudyplotOrAvalService(path, tabElement, externalUrl) {
      if (externalUrl != null) {
        return
      }
      // NOTE: redirect only after params set in profile! therefore no usage of b-link to and click together where order of execution indeterminated
      this.$store
        .dispatch(
          'profile/setObserverGroupOrStudyplotOrAvalServiceId',
          tabElement
        )
        .then(() => {
          if (this.$refs.obsModal) {
            this.$refs.obsModal.hide()
          }
          this.$router.push(
            path
              .replace(/:id/, tabElement.studyplotId)
              .replace(/:ldId/, tabElement.ldId)
          )
        })
    },
    getMessageCountToMenuItem(tabElement, itemType) {
      if (
        [ASSESSMENT_SITUATION, ASSESSMENT_SECTOR, ASSESSMENT_DECISION].includes(
          itemType
        )
      ) {
        return this.getMessagesToMenuItem(tabElement, itemType, moment(), false)
          .length
      } else {
        return this.getMessagesToMenuItem(tabElement, itemType, moment(), true)
          .length
      }
    },
    hasMissingAvalanches() {
      const obsAndAvals = this.observationsAndAvalanches
      return (
        !this.hasAvalanchesToday(obsAndAvals) &&
        (this.hasToReportAvalForTodayOrYesterday(obsAndAvals) ||
          this.hasToReportOlderAval())
      )
    },
    /*eslint-disable complexity*/
    hasToReportAvalForTodayOrYesterday(obsAndAvals) {
      let result = false
      for (let index = 0; index < obsAndAvals.length; index++) {
        const element = obsAndAvals[index]
        if (
          (element.type === DANGERSIGN ||
            element.type === STUDYPLOTOBSERVATION) &&
          moment(element.cTimestamp).isSame(moment(), 'day')
        ) {
          const avalRelease = element.dangersign.avalRelease
          if (
            (avalRelease.currentDay && avalRelease.currentDay === 'true') ||
            (avalRelease.previousDay && avalRelease.previousDay === 'true')
          ) {
            result = true
            break
          }
        }
      }
      return result
    },
    /*eslint-enable complexity*/
    hasToReportOlderAval() {
      const reportOlderAval = this.$store.state.avalanche.reportOlderAvalanche
      const reportOlderAvalDate =
        this.$store.state.avalanche.reportOlderAvalancheDate
      if (reportOlderAvalDate) {
        if (!moment(reportOlderAvalDate).isSame(moment(), 'day')) {
          this.$store.commit('avalanche/SET_REPORT_OLDER_AVAL_DATE', undefined)
          this.$store.commit('avalanche/SET_REPORT_OLDER_AVAL', false)
          return false
        }
        return reportOlderAval
      }
      return false
    },
    hasAvalanchesToday(obsAndAvals) {
      let result = false
      for (let index = 0; index < obsAndAvals.length; index++) {
        const element = obsAndAvals[index]
        const avalLocalCreationDate = moment.utc(element.date).local()
        if (
          element[AVALANCHE] &&
          avalLocalCreationDate.isSame(moment(), 'day')
        ) {
          result = true
          break
        }
      }
      return result
    },
    getCountPillClasses(menuType) {
      let classes = 'count-pills '
      if (menuType === AVALANCHE && this.hasMissingAvalanches()) {
        return (classes += 'count-pills-bak-red')
      } else if (menuType === LDREGION) {
        // return 'd-none'
        return (classes += 'count-pills-bak-grey')
      }
      return (classes += 'count-pills-bak-grey')
    },
    messageCountForTypeVisible(messageType) {
      return (
        messageType !== STUDYPLOTOBSERVATION_SUMMER &&
        messageType !== STUDYPLOTOBSERVATION_MANAGEMENT &&
        messageType !== SNOW_PROFILE &&
        messageType !== SNOWPROFILE_FLAT &&
        messageType !== SNOWPROFILE_SLOPE
      )
    },
  },
}
</script>

<style>
.count-pills {
  color: #6d6d6d !important;
  font-size: 11px !important;
}
.count-pills-bak-grey {
  background-color: #dbdbdb !important;
  font-weight: normal !important;
}
.count-pills-bak-red {
  background-color: red !important;
  color: white !important;
  font-weight: bold !important;
}
#obsModal .row {
  margin-bottom: 5px;
}
.obs-selection {
  margin-bottom: 5px;
}
.obs-button {
  width: 50px;
}
.obs-button img {
  width: 35px;
}
.tab-wrapper > a.disabled {
  color: #dbdbdb !important;
  cursor: default !important;
}
.tab-wrapper > a {
  color: #6d6d6d !important;
}
.tab-wrapper > a:hover {
  text-decoration: none;
}
.nav-link {
  color: #6d6d6d !important;
}
</style>
