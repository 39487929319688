<script>
import { DangersignAvalancheStyle } from '../styles/dangersignStyling/DangersignAvalancheStyle'
import { WhumpfCrackStyle } from '../styles/dangersignStyling/WhumpfCrackStyle'
import { SnowprofileStyle } from '../styles/dangersignStyling/snowprofileStyling/SnowprofileStyle'
import Legend from '../Legend'
import AbstractMapView from '../observation/AbstractMapView'
import { Canceler } from '@/scripts/requestUtil.js'
import {
  SNOWPROFILE,
  WHUMPF_CRACKS,
  DANGERSIGN_AVALANCHE,
} from '@/scripts/const.js'
import { deepClone } from '@/scripts/common'
import Feature from 'ol/Feature'

const canceler = Canceler.getCancelObj()

export default {
  // Scoped Styles werden seit VUE3 mit extends nicht mehr vererbt.
  // Daher der zusätzliche Import hier im Styles-Bereich
  extends: AbstractMapView,
  // eslint-disable-next-line vue/no-reserved-component-names
  components: { Legend },
  data() {
    return {
      whuCrackStyle: new WhumpfCrackStyle(undefined, undefined),
      snowProfStyle: new SnowprofileStyle(undefined, undefined),
    }
  },
  methods: {
    findAndShowObservation(observationId, featureCollection) {
      let feature
      const index = featureCollection.features.findIndex((item) => {
        if (item.properties) {
          return item.properties.id === observationId
        } else if (item.get('id')) {
          return item.get('id') === observationId
        } else {
          return false
        }
      })
      if (index > -1) {
        feature = null
        this.setDetailModalToShow(feature, observationId)
      }
    },

    setIndividualProps() {
      this.style = new DangersignAvalancheStyle(undefined, undefined)
      this.legend = this.style.legend()
      this.typ = DANGERSIGN_AVALANCHE
    },

    needsResolutionChangeHandler() {
      return false
    },

    setSpecialClickHandler() {
      const self = this
      return function (feature) {
        let featureId
        const myArr = [WHUMPF_CRACKS, SNOWPROFILE]
        myArr.some((eleTyp) => {
          const featureTyp = feature.get(eleTyp)
          if (featureTyp) {
            featureId = feature.get('id')
            return featureId !== undefined
          }
        })
        if (featureId !== undefined) {
          self.setDetailModalToShow(feature, featureId)
        }
      }
    },

    setDetailModalToShow(feature, featureId) {
      this.obsIds = []
      this.aggregate = false
      if (feature?.get(SNOWPROFILE)) {
        const clonedFeatureValue = deepClone(feature).values_
        const tempFeature = new Feature(clonedFeatureValue.geometry)
        tempFeature.setProperties(clonedFeatureValue, true)
        this.detailValue = [tempFeature]
        this.$bvModal.show('detailModalSnowprofile')
      } else {
        this.obsIds.push(featureId)
        this.aggregate = true
        this.$bvModal.show('detailModal')
      }
    },

    getObservationData(obsDate) {
      console.debug('Cancel all requests on reload')
      this.cancelRequests()
      const self = this
      const featureCollections = {
        obsFeatureCol: undefined,
        snowProfileFeatureCol: undefined,
      }

      return new Promise(function (resolve) {
        self.$store
          .dispatch('dangersign/loadDangersignComposite', {
            typ: DANGERSIGN_AVALANCHE,
            obsDate: obsDate,
            cancel: canceler,
          })
          .then((compositePromises) => {
            Promise.all(compositePromises).then((compositePromiseResult) => {
              // Wumm-Riss in die Collection übernehmen
              featureCollections.obsFeatureCol = deepClone(
                compositePromiseResult[0]
              )

              // Snowprofile in die Collection übernehmen
              const originalSnowprofileFeatures = deepClone(
                self.$store.state.snowprofile.snowprofileObsFeatures
              )
              if (originalSnowprofileFeatures?.features?.length > 0) {
                featureCollections.snowProfileFeatureCol =
                  originalSnowprofileFeatures
              } else {
                featureCollections.snowProfileFeatureCol = []
              }
              resolve(self.getSortedFeatureCollection(featureCollections))
            })
          })
      })
    },

    getSortedFeatureCollection(featureCollections) {
      // GefahrenIndex: 0=günstig, 1=mittel, 2=ungünstig ==> entspricht zudem dem zIndex
      let allFeatures = []
      for (let gefahrIndex = 0; gefahrIndex < 3; gefahrIndex++) {
        // Snowprofiles einordnen
        allFeatures = [
          ...allFeatures,
          ...this.snowProfStyle.getSnowprofileZindexForGefahrIdx(
            featureCollections.snowProfileFeatureCol.features,
            gefahrIndex
          ),
        ]

        // Wumm und Riss einordnen
        allFeatures = [
          ...allFeatures,
          ...this.whuCrackStyle.getWuhmpfCracksZindexForGefahrIdx(
            featureCollections.obsFeatureCol.features,
            gefahrIndex
          ),
        ]
      }

      featureCollections.obsFeatureCol.features = allFeatures
      return featureCollections.obsFeatureCol
    },

    cancelRequests() {
      if (canceler) {
        canceler.cancelAll()
      }
    },
  },

  beforeUnmount() {
    console.debug('Cancel all requests before destroy :/')
    this.cancelRequests()
  },
}
</script>

<style lang="css" scoped src="../observation/AbstractMapView.css"></style>
